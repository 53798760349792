import React from 'react'
// import Tile from './Tile'

export default function index() {
    return (
        <div>
            <p>
            Welcome to the arcade. More editing and styling to come in the future, but I've pressing matters. Minesweeper needs a restart before you can play, but works great! It was tough to build. snake is great! and way easier to build in vanilla js. But this is the React Arcade! React games only! 
            Instructions are needed for them all, but consider it a hard game mechanic that you have to figure them out, eh? 
            </p>
            <p>
            If you're part of Code Pals, and have discovered this, a great game for you to work on to display as your own is:
          
    <a href="https://react-mancala.netlify.app/" rel="noopener noreferrer" target="_blank" class="arcade-link">mancala</a>
            </p>
            <p>
                this page itself needs work, and the comments below show my intentions. Students of mine can use it for practice, and potential employers of mine can use it to see how responsive I have to feedback and direction. 
    </p>        
    {/* <Tile game="" notes="" image={}/>
            <Tile game="" notes="" image={}/>
            <Tile game="" notes="" image={}/>
            <Tile game="" notes="" image={}/> */}
            
            
        </div>
    )
}
